angular.module('fingerink')
    .directive('sendRellenarDocument', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'web/main/send/steps/signInPerson/sendRellenarDocument.tpl.html',
            scope: {
                sign: '=',
                parent: '='
            },
            controllerAs: 'controller',

            controller: 'sendRellenarDocumentCtrl'
        };
    })
    .controller('sendRellenarDocumentCtrl', function ($rootScope, $scope, $location, $anchorScroll, templateService, signatureService, $timeout, $translate, session) {

        var that = this;
        that.sign = $scope.sign;
        that.parent = $scope.parent;
        that.actualFirmanteIndex = 0;
      /*
        that.actualFirmante = that.sign.signers[0];
        that.selectedwidgeetIndex = 0;
        that.selectedwidgeet = that.actualFirmante.fields[0];
      */  
        that.sender = session.get().user;


        swal($translate.instant(that.actualFirmante.name + ', es tu turno de firma'), $translate.instant('Por favor, rellena los campos que ' + that.sender.name + ' te ha solicitado'), 'info');


        that.siguiente = function () {
            if (that.selectedwidgeetIndex < that.actualFirmante.fields.length - 1) {
                that.selectedwidgeetIndex++;
                that.selectedwidgeet = that.actualFirmante.fields[that.selectedwidgeetIndex];
                that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                return;
            } else {
                for (var i = 0; i < that.actualFirmante.fields.length; i++) {
                    if (!that.actualFirmante.fields[i].answer) {
                        that.selectedwidgeet = that.actualFirmante.fields[i];
                        that.selectedwidgeetIndex = i;
                        that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                        swal($translate.instant("Hay campos sin rellenar"), $translate.instant("Por favor, rellena todos los campos para poder completar la firma."));
                        return;
                    }
                }
            }

            if (that.actualFirmanteIndex < that.sign.signers.length - 1) {
                that.actualFirmanteIndex++;
                that.actualFirmante = that.sign.signers[that.actualFirmanteIndex];
                that.selectedwidgeet = that.actualFirmante.fields[0];
                that.selectedwidgeetIndex = 0;
                that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                swal($translate.instant(that.actualFirmante.name + ' ' + 'es tu turno de firma'), $translate.instant('Por favor, rellena los campos que' + ' ' + that.sender.name + ' ' + 'te ha solicitado'), 'info');
            } else {
                that.parent.siguiente();
            }
        };

        that.anterior = function () {
            if (that.selectedwidgeetIndex > 0) {
                that.selectedwidgeetIndex--;
                that.selectedwidgeet = that.actualFirmante.fields[that.selectedwidgeetIndex];
                that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);

                return;
            }

            if (that.actualFirmanteIndex > 0) {
                that.actualFirmanteIndex--;
                that.actualFirmante = that.sign.signers[that.actualFirmanteIndex];
                that.selectedwidgeet = that.actualFirmante.fields[0];
                that.selectedwidgeetIndex = 0;
                that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                swal($translate.instant(that.actualFirmante.name + ' ' + 'es tu turno de firma'), $translate.instant('Por favor, rellena los campos que' + ' ' + that.sender.name + ' ' + 'te ha solicitado'), 'info');

            } else {
                that.parent.anterior();
            }

        };



        that.goTo = function (a, w) {
            $location.hash(a);
            $anchorScroll();
            that.selectedwidgeet = w;

        };

        that.call = function (id) {
            // $rootScope.$broadcast('call' + id, 'call');
        };

        $timeout(function () {
            that.goTo('campo' + that.actualFirmanteIndex + '_' + 0, that.selectedwidgeet);
        }, 10);

    });
